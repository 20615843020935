@import "../../global.scss";

.experience {
    background-color: crimson;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .arrow {
        height: 60px;
        position: absolute;
        cursor: pointer;

        @include mobile {
            display: none;
        }

        &.left {
            left: 100px;
            transform: rotateY(180deg);

            @include widescreen {
                left: 10px;
            }

            @include tablet {
                left: 10px;
            }

        }

        &.right {
            right: 100px;

            @include widescreen {
                right: 10px;
            }

            @include tablet {
                right: 10px;
            }
        }
    }

    .slider {
        height: 450px;
        display: flex;
        position: absolute;
        left: 0;
        transition: all 1s ease-out;

        @include mobile {
            height: 100vh;
            flex-direction: column;
            justify-content: center;
        }

        .container {
            width: 100vw;
            display: flex;
            align-items: center;
            justify-content: center;

            // @include tablet {
            //     width: 100vw;
            // }

            .item {
                width: 1000px;
                height: 100%;
                background-color: white;
                border-radius: 20px;
                display: flex;
                justify-content: center;

                @include tablet {
                    width: 80%;
                    height: 100%;
                    // margin: 15px 0;
                }

                @include mobile {
                    width: 80%;
                    height: 150px;
                    margin: 15px 0;
                }
             
                .left {
                    flex: 4;
                    height: 100%;
                    // background-color: yellow;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    @include mobile {
                        // height: 100%;
                    }

                    .leftContainer {
                        width: 90%;
                        // height: 85%;
                        height: 50%;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        align-items: center;

                        a {
                            color: crimson;
                            font-weight: 500;
                        }

                        @include mobile {
                            width: 100%;
                            // height: 100%;
                            // text-align: center;
                        }
                    }

                    .imgContainer {
                        width: 80px;
                        height: 80px;
                        border-radius: 50%;
                        // background-color: rgb(245,179,155);
                        background-color: lightgray;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        @include mobile {
                            width: 20px;
                            height: 20px;
                        }

                        img {
                            // width: 25px;

                            @include mobile {
                                // width: 15px;
                            }

                        }
                    }

                    h2 {
                        font-size: 20px;

                        @include tablet {
                            font-size: 16px;
                        }

                        @include mobile {
                            font-size: 13px;
                        }
                    }

                    p {
                        font-size: 16px;
                        text-align: center;

                        @include tablet {
                            font-size: 14px;
                        }

                        @include mobile {
                            display: none;
                        }
                    }

                    span {
                        font-size: 12px;
                        font-weight: 600;
                        text-decoration: underline;
                        cursor: pointer;
                    }
                }

                .right {
                    flex: 6;
                    height: 100%;
                    // background-color: green;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    overflow: hidden;

                    // img {
                    //     // width: 400px;
                    //     // width: 90%;
                    //     // transform: rotate(-10deg);
                    // }
                }
            }
        }
    }


}