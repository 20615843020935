@import "../../global.scss";

.portfolioList {
        font-size: 18px;
        margin-right: 50px;
        padding: 7px;
        border-radius: 10px;
        cursor: pointer;
        // height: 90%;

        @include tablet {
            // margin-right: 20px;
            font-size: 16px;
        }

        @include mobile {
            margin-right: 20px;
            font-size: 14px;
        }

        &.active {
            background-color: $mainColour;
            color: white;
        }
    }