$mainColour:#15023a;

$mobilewidth: 768px;
$tabletwidth: 1090px;
$wideWidth: 1350px;

@mixin mobile {
    @media (max-width: #{$mobilewidth}) {
        @content
    }
};

@mixin tablet {
    @media (max-width: #{$tabletwidth}) {
        @content
    }
};

@mixin widescreen {
    @media (max-width: #{$wideWidth}) {
        @content
    }
};