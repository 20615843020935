@import "../../global.scss";

.intro {
    background-color: white;
    display: flex;

    @include mobile {
        flex-direction: column;
        align-items: center;
    }

    .left {
        // splitting the page into two sections
        flex: 0.5;
        // background-color: yellow;

        overflow: hidden;

        .imgContainer {
            width: 700px;
            height: 700px;
            background-color: crimson;
            //makes background a circle
            border-radius: 50%;
            // centering image
            display: flex;
            align-items: flex-end;
            justify-content: center;
            // making it appear on the left of the section
            float: right;

            @include mobile {
                align-items: start;
            }

            img {
                height: 110%;
                width: auto;
                margin-right: 30px;

                @include mobile {
                    height: 80%;
                }
            }
        }
    }
    
    .right {
        flex: 0.5;
        // background-color: red;
        position: relative;

        .wrapper {
            width: 100%;
            height: 100%;
            padding-left: 50px;
            display: flex;
            // making items verticle
            flex-direction: column;
            justify-content: center;

            @include mobile {
                padding-left: 0px;
                align-items: center;
            }

            h1 {
                font-size: 60px;
                margin: 10px 0;

                @include mobile {
                    font-size: 40px;
                }
            }
            h2 {
                font-size: 35px;
            }
            h3 {
                font-size: 30px;

                
                @include mobile {
                    font-size: 20px;
                }

                span {
                    font-size: inherit;
                    color: crimson;
                }

                // giving the ityped cursor an animation
                .ityped-cursor {
                    animation: blink 1s infinite;
                }

                // creating the blink animation for the ityped cursor
                @keyframes blink {

                    50% {
                        opacity: 1;
                    }

                    100% {
                        opacity: 0;
                    }
                }
            }
        }
        a {
            position: absolute;
            bottom: 10px;
            left: 0%;

            @include mobile {
                display: none;
            }

            img {
                width: 50px;
                // animating the arrow
                animation: arrowBlink 2s infinite;
            }

            @keyframes arrowBlink {
                100% {
                    // hides the arrow every cycle of the animation
                    opacity: 0;
                }
            }
        }

    }
}