@import "../../global.scss";

.header {
    width: 100%;
    height: 70px;
    background-color: white;
    color: $mainColour;
    // Keeping header at the top of the page
    position: fixed;
    top: 0;
    z-index: 3;
    // makes the transion of animations slower
    transition: all 1s ease;

    .wrapper {
        padding: 10px 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .left {

            display: flex;
            align-items: center;

            .logo{

                margin-right: 40px;

                .logo-image{
                    // height: calc(70px - 10px);
                    height: 46px;
                    width: auto;
                }
            }
        }

        .itemContainer {
            display: flex;
            align-items: center;
            margin-left: 30px;

            @include mobile {
                display: none;
            }

            .icon {
                font-size: 18px;
                margin-right: 5px;
            }

            span {
                font-size: 15px;
                font-weight: 500;
            }
        }

        .right {

            .hamburger {
                width: 32px;
                height: 25px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                cursor: pointer;

                span {
                    width: 100%;
                    height: 3px;
                    background-color: $mainColour;
                    // changes the starting point of the transform from the center of the element to the left of the element
                    // Eg. if rotate is 45deg is will keep the left most point as the anchor
                    transform-origin: left;
                    transition: all 2s ease;
                }

            }
        }
    }

    // and if there is a class called "active"
    &.active{
        background-color: $mainColour;
        color: white;

        .hamburger {
            span {
                &:first-child{
                    background-color: white;
                    transform: rotate(45deg);
                }
                &:nth-child(2){
                    //making the second child invisible
                    opacity: 0;
                }
                &:last-child{
                    background-color: white;
                    transform: rotate(-45deg);
                }
                
            }
        }
    }
}