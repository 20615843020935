@import "../../global.scss";

.portfolio {
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    // overflow: visible;

    h1 {
        font-size: 50px;
        // height: min-content;
        overflow-x: visible;

        @include tablet {
            font-size:  35px;
        }

        @include mobile {
            font-size: 30px;
        }
    }

    ul {
        margin: 10px;
        padding: 0;
        list-style: none;
        display: flex;
        overflow-x: visible;

        @include tablet {
            margin: 0;
        }

        @include mobile {
            flex-wrap: wrap;
            justify-content: center;
            margin: 10px 0;
        }

    }

    .container {
        // width: 70%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;

        margin-top: 10px;
        // height: 100%;

        @include tablet {
            // width: 70%;
        }

        @include mobile {
            width: 100%;
        }

        .item {

            // width: 400px;
            width: auto;
            max-width: 500px;
            min-width: 300px;
            height: 300px;
            border-radius: 20px;
            border: 1px solid rgb(240, 239, 239);
            margin: 10px 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: white;
            position: relative;
            transition: all .5s ease;
            cursor: pointer;

            @include tablet {
                width: 300px;
                height: 200px;
            }

            @include mobile {
                width: 200px;
                height: 150px;
            }

            h3 {
                position: absolute;
                font-size: 20px;
            }

            img {
                // height: 200px;
                // width: auto;
                // height: auto;
                height: 100%;
                width: 100%;
                object-fit: cover;
                z-index: 1;
            }

            &:hover {
                background-color: $mainColour;
                img {
                    opacity: 0.2;
                    z-index: 0;
                }
            }
        }
    }
}