@import "../../global.scss";

.portfolioItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 70px;

    .container {
        width: 70%;
        text-align: center;
        // display: flex;
        // align-items: center;
        // justify-content: center;
        // flex-wrap: wrap;

        .title {
            font-size: 50px;
            margin: 0 0 1em 0;
    
            @include mobile {
                font-size: 20px;
            }
        }

        .projectLinkParagraph {
            margin: 10px 0 5vh;
        }

        h3, h2 {
            padding: 10px 0 0;
            color: crimson;
            font-weight: 600;
        }

        ul {
            // removes the weird padding at the front of the ul
            list-style-type: none;
            margin: 0;
            padding: 0;
            display: flex;
            justify-content: center;

            @include mobile {
                flex-direction: column;
            }

            li {
                padding: 5px;
                font-weight: 500;
            }
        }
    
        img {
            margin: 10px 0;
            max-width: 1000px;
            width: 100%;
            height: auto;
        }

        p {
            margin-top: 10px;
            font-size: 16px;
            font-weight: 400;

            a {
                color: crimson;
                font-weight: 500;
            }
        }
    }
}
